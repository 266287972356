@use "./styles-utils.scss" as *;

.WalletTrustlines {
  &__asset {
    display: flex;
    justify-content: space-between;
    gap: pxToRem(16px);
    border: 1px solid var(--color-gray-30);
    border-radius: pxToRem(4px);
    background-color: var(--color-gray-00);
    padding: pxToRem(6px) pxToRem(10px);

    &__info {
      display: flex;
      flex-direction: column;
      gap: pxToRem(8px);
      font-size: pxToRem(14px);
      line-height: pxToRem(22px);
      font-weight: var(--font-weight-regular);
      color: var(--color-gray-80);

      span {
        color: var(--color-gray-60);
        font-size: pxToRem(12px);
      }
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
  }
}
